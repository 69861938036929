var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "categoryManagement-list" },
    [
      _c("app-list", {
        ref: "myList",
        attrs: { opt: _vm.opt },
        on: { get: _vm.onGet }
      }),
      _c(
        "div",
        { staticClass: "categoryDetail" },
        [
          _c(
            "el-dialog",
            {
              attrs: {
                title: _vm.addTategoryTitle,
                visible: _vm.dialogVisibleTrade,
                "close-on-click-modal": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisibleTrade = $event
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "editBox" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: { model: _vm.form, rules: _vm.rules }
                    },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            "label-width": "158px",
                            label: "选角色",
                            prop: "type"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: {
                                width: "320px",
                                display: "inline-block"
                              },
                              attrs: {
                                filterable: "",
                                placeholder: "请选择",
                                disabled: _vm.notEdit
                              },
                              on: {
                                change: function($event) {
                                  _vm.typeChange(_vm.form.type)
                                }
                              },
                              model: {
                                value: _vm.form.type,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "type", $$v)
                                },
                                expression: "form.type"
                              }
                            },
                            _vm._l(_vm.typeList, function(item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            "label-width": "158px",
                            label: _vm.label,
                            prop: "merchantId"
                          }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: {
                                width: "320px",
                                display: "inline-block"
                              },
                              attrs: {
                                filterable: "",
                                disabled: _vm.notEdit,
                                placeholder: _vm.placeholder,
                                clearable: ""
                              },
                              model: {
                                value: _vm.form.merchantId,
                                callback: function($$v) {
                                  _vm.$set(_vm.form, "merchantId", $$v)
                                },
                                expression: "form.merchantId"
                              }
                            },
                            _vm._l(_vm.nameList, function(it) {
                              return _c("el-option", {
                                key: it.value,
                                attrs: { label: it.label, value: it.value }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            "label-width": "158px",
                            label: "商品品类名称",
                            prop: "name"
                          }
                        },
                        [
                          _c("el-input", {
                            staticStyle: {
                              width: "320px",
                              display: "inline-block"
                            },
                            attrs: {
                              disabled: _vm.notEdit,
                              maxlength: "50",
                              placeholder: "请输入商品名称",
                              clearable: ""
                            },
                            model: {
                              value: _vm.form.name,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "name",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.name"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            "label-width": "158px",
                            label: "品类发票编码",
                            prop: "code"
                          }
                        },
                        [
                          _c("el-input", {
                            staticStyle: {
                              width: "320px",
                              display: "inline-block"
                            },
                            attrs: {
                              maxlength: "64",
                              placeholder: "请输入品类发票编码",
                              clearable: ""
                            },
                            model: {
                              value: _vm.form.code,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "code",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.code"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { "label-width": "158px", label: "备注" } },
                        [
                          _c("el-input", {
                            staticStyle: {
                              width: "320px",
                              display: "inline-block"
                            },
                            attrs: {
                              type: "textarea",
                              rows: "4",
                              maxlength: "100",
                              placeholder: "请输入备注",
                              clearable: ""
                            },
                            model: {
                              value: _vm.form.remarks,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "remarks", $$v)
                              },
                              expression: "form.remarks"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            "label-width": "158px",
                            label: "设置分润比例（%）",
                            prop: "deductRate"
                          }
                        },
                        [
                          _c("el-input", {
                            staticStyle: {
                              width: "320px",
                              display: "inline-block"
                            },
                            attrs: {
                              placeholder: "请输入分润比例",
                              clearable: ""
                            },
                            model: {
                              value: _vm.form.deductRate,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.form,
                                  "deductRate",
                                  typeof $$v === "string" ? $$v.trim() : $$v
                                )
                              },
                              expression: "form.deductRate"
                            }
                          }),
                          _c("div", { staticStyle: { color: "#888888b5" } }, [
                            _vm._v(
                              "平台与商家结算的佣金比率，请填写0-100间的数字，精确到小数点后两位"
                            )
                          ])
                        ],
                        1
                      ),
                      _c("div", { staticClass: "stateTitle" }, [
                        _vm._v("启用状态")
                      ]),
                      _c("el-switch", {
                        staticStyle: { display: "inline-block" },
                        attrs: {
                          "active-color": "#67C23A",
                          "inactive-color": "#F56C6C",
                          "active-text": "启用",
                          "inactive-text": "禁用"
                        },
                        model: {
                          value: _vm.form.stateStr,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "stateStr", $$v)
                          },
                          expression: "form.stateStr"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function($event) {
                          _vm.submit()
                        }
                      }
                    },
                    [_vm._v("提交")]
                  )
                ],
                1
              )
            ]
          ),
          _c(
            "el-dialog",
            {
              attrs: {
                title: "选择文件",
                visible: _vm.dialogVisibleUpload,
                "close-on-click-modal": false
              },
              on: {
                "update:visible": function($event) {
                  _vm.dialogVisibleUpload = $event
                },
                close: function($event) {
                  _vm.closeFun()
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "up_add_bg", on: { click: _vm.onImgFunBtn } },
                [
                  _vm._v("\n        选择文件\n        "),
                  _c("input", {
                    ref: "inputer",
                    staticClass: "upload-btn",
                    attrs: {
                      type: "file",
                      id: "fileupload",
                      multiple: "false",
                      accept:
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                    },
                    on: { change: _vm.addFile }
                  })
                ]
              ),
              _c("div", { staticClass: "upload_fileName" }, [
                _vm._v("文件名称：" + _vm._s(_vm.fileName))
              ]),
              _c(
                "div",
                { staticClass: "dialog_bottom" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary", disabled: _vm.sureBtnFlag },
                      on: { click: _vm.sureBtn }
                    },
                    [_vm._v("关闭")]
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }