<template>
  <div class="categoryManagement-list">
    <app-list :opt="opt" @get="onGet" ref="myList"></app-list>

    <div class="categoryDetail">
      <!-- <app-detail :opt="opt" ref="detail"></app-detail> -->
      <el-dialog :title="addTategoryTitle" :visible.sync="dialogVisibleTrade" :close-on-click-modal="false">
        <div class="editBox">
          <el-form ref="form" :model="form" :rules="rules">
            <el-form-item label-width='158px' label="选角色" prop="type">
              <el-select style="width: 320px;display: inline-block;" filterable v-model="form.type" placeholder="请选择" :disabled="notEdit" @change="typeChange(form.type)">
                <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label-width='158px' :label="label" prop="merchantId">
              <el-select style="width: 320px;display: inline-block;" filterable v-model="form.merchantId"
                :disabled="notEdit" :placeholder="placeholder" clearable>
                <el-option v-for="it in nameList" :key="it.value" :label="it.label" :value="it.value"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label-width='158px' label="商品品类名称" prop="name">
              <el-input :disabled="notEdit" maxlength="50" style="width: 320px;display: inline-block;"
                placeholder="请输入商品名称" v-model.trim="form.name" clearable>
              </el-input>
            </el-form-item>
            <el-form-item label-width="158px" label="品类发票编码" prop="code">
              <el-input maxlength="64" style="width: 320px;display: inline-block;" placeholder="请输入品类发票编码"
                v-model.trim="form.code" clearable>
              </el-input>
            </el-form-item>
            <el-form-item label-width="158px" label="备注">
              <el-input type="textarea" rows="4" maxlength="100" style="width: 320px;display: inline-block;"
                placeholder="请输入备注" v-model="form.remarks" clearable>
              </el-input>
            </el-form-item>
            <el-form-item label-width="158px" label="设置分润比例（%）" prop="deductRate">
              <el-input style="width: 320px;display: inline-block;" placeholder="请输入分润比例" v-model.trim="form.deductRate"
                clearable>
              </el-input>
              <div style="color:#888888b5;">平台与商家结算的佣金比率，请填写0-100间的数字，精确到小数点后两位</div>
            </el-form-item>
            <div class="stateTitle">启用状态</div>
            <el-switch style="display: inline-block" v-model="form.stateStr" active-color="#67C23A"
              inactive-color="#F56C6C" active-text="启用" inactive-text="禁用">
            </el-switch>
          </el-form>
        </div>
        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="submit()">提交</el-button>
        </span>
      </el-dialog>
      <el-dialog title="选择文件" :visible.sync="dialogVisibleUpload" @close="closeFun()" :close-on-click-modal="false">
        <div class="up_add_bg" @click='onImgFunBtn'>
          选择文件
          <input type="file" class="upload-btn" @change="addFile" ref="inputer" id="fileupload" multiple='false'
            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel">
        </div>
        <div class="upload_fileName">文件名称：{{fileName}}</div>
        <!-- .xls .xslx 格式 -->
        <div class="dialog_bottom">
          <el-button type="primary" :disabled="sureBtnFlag" @click="sureBtn">关闭</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
  import cabinetUtil from "@utils/cabinetUtil.js"
  let tradeNameVli = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback(new Error("请输入长度不超过50位的商品名称"));
    } else {
      //支持汉字、大小写字母、阿拉伯数字、以及中英文标点符号，不超过50个字符
      let reg = /[^\u4E00-\u9FA5A-z0-9`~!@#$%^&*()_\-+=<>?:"{}|,.\/;'\\[\]·？！、；：。，“”‘’（ ）：《 》〈 〉【】『』「 」﹃﹄〔〕…—～﹏￥]/g;
      let reg2 = /\s/; //空白符
      if (reg.test(value)) {
        callback(new Error("只能包含中文、字母、数字"));
      } else if (reg2.test(value)) {
        callback(new Error("商品名称不能含有空格"));
      } else {
        callback();
      }
    }
  };

  let validCode = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback();
    } else {
      //支持输入大小写字母，数字，限制1~16位字符
      let reg = /^[A-z0-9]{1,64}$/;
      if (!reg.test(value)) {
        callback(new Error("商品品类编号格式不对"));
      } else {
        callback();
      }
    }
  };
  let validInputCode = (rule, value, callback) => {
    if (value == "" || value == undefined) {
      callback(new Error("请输入品类发票编码"));
    } else {
      //支持输入大小写字母，数字，限制1~16位字符
      let reg = /^[A-z0-9]{1,64}$/;
      if (!reg.test(value)) {
        callback(new Error("商品品类编号格式不对"));
      } else {
        callback();
      }
    }
  };
  let validDeductRate = (rule, value, callback) => {
    // 0-100之间的数据 包含0 不能是001
    var reg = /(^(?:0|[1-9][0-9]?|100)(\.[0-9]{0,2})?)$/
    if (!reg.test(value)) {
      callback(
        new Error("请输入0-100之间的数字，且小数点后最多保留2位")
      );
    } else {
      if (value && Number(value) > 100) {
        callback(new Error("请输入小于100的数"));
      } else {
        callback();
      }
    }

  };
  import axios from "axios";
  export default {
    data() {
      let _this = this;
      return {
        notSubmitFlage: false,
        fileName: '',
        formData: new FormData(),
        file: {}, //文件数据  
        dialogVisibleUpload: false,
        sureBtnFlag: false,
        dialogVisibleTrade: false,
        addTategoryTitle: "添加商品品类",
        categoryId: "", //商品品类id
        typeValue: 0, // 0-添加商品品类 1-编辑商品品类
        merchantList: [],
        nameList: [],
        label: '商家名称',
        placeholder: "根据商家名称搜索",
        typeList:[
          {
            value: "1",
            label: '商家'
          },
          {
            value: "3",
            label: '经销商'
          },
        ],
        notEdit: false,
        form: {
          name: "",
          code: "",
          remarks: "",
          stateStr: false,
          deductRate: '',
          merchantId: '',
          type: "1",
        },
        rules: {
          merchantId: [{
            required: true,
            message: "请输入选择",
          }],
          name: [{
            required: true,
            trigger: ['change', 'blur'],
            validator: tradeNameVli
          }],
          code: [{
            required: true,
            trigger: ['change', 'blur'],
            validator: validInputCode,
          }],
          deductRate: [{
            required: true,
            trigger: ['change', 'blur'],
            validator: validDeductRate,

          }],
        },
        dialogVisible: false,
        opt: {
          title: "商品品类管理",
          search: [
            {
              key: "type",
              label: "角色",
              value: "1",
              type:"selectMust",
              opt: {
                list: [
                  {
                    value: "1",
                    label: '商家'
                  },
                  {
                    value: "3",
                    label: '经销商'
                  },
                ],
                change(data,cb) {
                    let findMerchant = _this.opt.search.find(item => item.key == 'merchantId');
                    if(findMerchant) {
                        cb({
                            value: "",
                            key: findMerchant.key
                        });
                        findMerchant.opt.list = [];
                    }
                    _this.getMerchantList(data)
                }
              }
            },
            {
              key: "merchantId",
              label: "商户名称",
              type: 'remoteSearchById',
              opt: {
                list: [],
              }
            }, {
              key: "name",
              label: "商品品类",
            },
            {
              key: "categoryCode",
              label: "商品品类编号", //这个字段目前是发票编码的值，有所修改，到时需要重新对接
              maxlength: 64,
              rules: [{
                validator: validCode,
                trigger: ['blur']
              }]
            }
          ],
          columns: [{
              label: "商品品类",
              key: "name"
            },
            {
              label: "商品品类编号",
              key: "categoryCode"
            },
            {
              label: "品类发票编码",
              key: "code"
            },
            {
              label: "角色",
              key: "role",
              align: "center"
            },
            {
              label: "商户名称",
              key: "merchantName",
              align: "center"
            },
            {
              label: "分润比例",
              key: "deductRate"
            },
            {
              label: "创建时间",
              key: "createdTime"
            },
            {
              label: "创建人",
              key: "createdBy"
            },
            {
              label: "备注",
              key: "remarks"
            },
            {
              label: "状态",
              key: "stateStr"
            },
          ],
          buttons: [{
              type: 0,
              on() {
                _this.addCategoryInfo();
              }
            },
            {
              type: 1,
              on(row) {
                _this.editCategoryInfo(row.data);
              }
            },
            {
              type: 2,
              on(row) {
                _this.deleteCategoryInfo(row);
              }
            },
            {
              type: 3,
              name: "批量导入",
              on() {
                _this.dialogVisibleUpload = true;
                _this.fileName = "";
              }
            },
          ],
          exportButtons: [{
            type: "Export",
            url: this.EXport + "/mall-service/category/export",
            listName: '品类列表'
          }],
        },
      };
    },
    created() {
      console.log("categoryManagement-list created!!");
    },
    async activated() {
      console.log("categoryManagement-list activated!!");
      try {
        let merchantFlag = this.getLoginType('loginType');
        let isDealerLogin = this.isDealerLogin();
        if (merchantFlag || isDealerLogin) {
          this.opt.title = '商品品类列表'
          this.opt.search.forEach((item, index) => {
            if (item.key == 'merchantId') {
              this.opt.search.splice(index, 1);
            }
          })
          this.opt.search.forEach((item, index) => {
            if (item.key == 'type') {
              this.opt.search.splice(index, 1);
            }
          })
          this.opt.columns.forEach((item, index) => {
            if (item.key == 'action') {
              this.opt.columns.splice(index, 1);
            }
          })
          this.opt.columns.forEach((item, index) => {
            if (item.key == 'merchantName') {
              this.opt.columns.splice(index, 1);
            }
          })
          this.opt.columns.forEach((item, index) => {
            if (item.key == 'role') {
              this.opt.columns.splice(index, 1);
            }
          })
        } else {
          this.getMerchantList(1)
        }
      } catch (error) {
        console.log(error)
      }
    },
    deactivated() {
      this.dialogVisibleTrade = false;
      this.dialogVisibleUpload = false;
    },

    methods: {
      onGet(opt) {
        let dto = {
          pageNum: opt.skip,
          pageSize: opt.limit,
          merchantId: opt.searchForm.merchantId == '0001' ? null : opt.searchForm.merchantId,
          // type: opt.searchForm.type ? opt.searchForm.type : '1',
          ...opt.searchForm
        };
        let userInfo = this.cache.getLS("userInfo");
        if(userInfo.roleType == '1'){
          dto.type = opt.searchForm.type ? opt.searchForm.type : '1'
        }else if(userInfo.roleType == '10'){
          dto.type = ''
        }else if(userInfo.roleType == '13'){
          dto.type = ''
        }
        this.post("mall-service/category/page/query", dto, {
          isUseResponse: true
        }).then(res => {
          res.data.data = res.data.list;
          res.data.data.forEach((item, index) => {
            item.action = [true, true];
            //后续在这里对默认的商品品类进行判断，不显示编辑和删除 设置item.action
            // 例如
            // if(index == 1 || index == 2) {
            //   item.action = [false, false];
            // }else {
            //   item.action = [true, true];
            // }
            item.stateStr = ["禁用", "启用"][item.state];
            item.remarks = item.remarks ? item.remarks : "--";
            item.deductRate = item.deductRate ? item.deductRate + '%' : '--';
            item.merchantName = item.merchantName ? item.merchantName : '平台默认';
            if(item.type == "1") {
              item.role = '商家'
            } else if (item.type == "3") {
              item.role = "经销商"
            }
          });
          opt.cb(res.data);
        });
        if(opt.searchForm.type !== "3") {
            this.getMerchantList(1);
        }else{
            this.getMerchantList(3);
        }
      },
      // 角色切换
      typeChange(val) {
        if (val == "1") {
          this.label = '商家名称';
          this.placeholder = "根据商家名称搜索";
          this.getNameList(1)
          this.form.deductRate = ''
        }else {
          this.label = '经销商名称';
          this.placeholder = "根据经销商名称搜索";
          this.getNameList(3)
          this.form.deductRate = "0"
        }
        this.form.merchantId = ''
      },
      // 提交
      submit() {
        this.notSubmitFlage = false;
        // 这一块代码之所以这么写是因为有两个refs 校验通过时不会走else
        this.$refs['form'].validate((valid) => {
          console.log('------------:', valid)
          if (!valid) {
            console.log('校验不通过')
            this.notSubmitFlage = true;
          } else {
            console.log('校验通过')
          }
        })
        if (this.notSubmitFlage) {
          return
        }
        let url;
        let message;
        let dto = {
          name: this.form.name,
          code: this.form.code,
          merchantId: this.form.merchantId == '0001' ? null : this.form.merchantId,
          state: this.form.stateStr ? 1 : 0,
          remarks: this.form.remarks,
          deductRate: this.form.deductRate,
          type: this.form.type
        };
        this.nameList.forEach(item => {
          if (item.value == this.form.merchantId) {
            dto.merchantName = item.label;
          }
        })
        if (this.typeValue == 0) {
          url = "mall-service/category/add";
          message = "添加商品品类成功！"
        } else if (this.typeValue == 1) {
          url = "mall-service/category/update";
          dto.id = this.categoryId;
          message = "编辑商品品类成功！"
        }
        this.post(url, dto).then(res => {
          this.dialogVisibleTrade = false;
          this.$message({
            showClose: true,
            message: message,
            type: "success"
          });
          this.$refs.myList.get();
        })
      },
      // 新增品类
      addCategoryInfo() {
        this.notEdit = false;
        this.dialogVisibleTrade = true;
        this.typeValue = 0; //添加商品品类
        this.addTategoryTitle = "添加商品品类";
        this.label = '商家名称';
        this.placeholder = "根据商家名称搜索";
        this.form = {
          name: "",
          code: "",
          merchantId: "",
          remarks: "",
          stateStr: false,
          deductRate: "",
          type: "1",
        }
        this.getNameList(1)
        // 对表单进行重置
        this.$nextTick(() => {
          this.$refs["form"].resetFields();
        })
      },
      // 获取商家名称和经销商名称下拉框
      getMerchantList(val) {
        let dto = {
          roleType: val
        }
        this.post('/mall-service/merchant/v1/query/list',dto).then(res => {
          res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
          res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
          this.merchantList = res
          this.merchantList.unshift({
            label: '平台默认',
            value: '0001'
          })
          this.opt.search.forEach((item, index) => {
            if (item.key == 'merchantId') {
              item.opt.list = this.merchantList;
            }
          })
        })
      },
      getNameList(val) {
        let dto = {
          roleType: val
        }
        if(val == 1) {
          dto.merchantTypeList = [1,2];
        }
        this.post('/mall-service/merchant/v1/query/list',dto).then(res => {
          res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
          res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
          this.nameList = res
          this.nameList.unshift({
            label: '平台默认',
            value: '0001'
          })
        })
      },
      // 编辑品类
      editCategoryInfo(data) {
        this.getCategoryInfo(data.id);
        this.categoryId = data.id;
        this.notEdit = true;
      },
      // 删除品类
      deleteCategoryInfo(data) {
        const h = this.$createElement;
        this.$msgbox({
          title: "确定提示",
          message: h("p", null, [
            h(
              "span", {
                style: "color: #333;font-size: 16px;margin-left:98px"
              },
              "确定要删除该商品品类吗？"
            )
          ]),
          showCancelButton: true,
          confirmButtonText: "确定",
          cancelButtonText: "取消"
        }).then(action => {
          let list = [];
          data.list.forEach(item => {
            list.push(item.id);
          })
          this.post("mall-service/category/deleteByIds", list).then(res => {
            this.$message({
              showClose: true,
              message: "删除商品品类成功!",
              type: "success"
            });
            this.$refs.myList.onRefresh();
          });
        });
      },
      //获取商品品类信息
      getCategoryInfo(id) {
        this.dialogVisibleTrade = true;
        this.typeValue = 1; //编辑商品品类
        this.addTategoryTitle = "编辑商品品类";
        this.get("mall-service/category/detail/" + id).then(res => {
          if (res.type == "1") {
            this.label = '商家名称';
            this.placeholder = "根据商家名称搜索";
            this.getMerchantList(1)
          }else {
            this.label = '经销商名称';
            this.placeholder = "根据经销商名称搜索";
            this.getMerchantList(3)
          }
          this.form = {
            name: res.name,
            code: res.code,
            merchantId: res.merchantId,
            remarks: res.remarks,
            stateStr: res.state == 1 ? true : false,
            deductRate: res.deductRate,
            type: res.type
          }
        })
      },
      onImgFunBtn() {
        document.getElementById('fileupload').click();
      },
      //选择文件
      addFile: function (e) {
        var _this = this;
        let inputDOM = this.$refs.inputer;
        this.file = inputDOM.files[0];
        let len = this.file.length;
        let size = Math.floor(this.file.size / 1024);
        if (size > 50 * 1024 * 1024) {
          alert("请选择50M以内的文件！");
          return false;
        }
        // this.showMask();
        this.sureBtnFlag = true;
        this.formData.append("file", this.file);
        axios({
            method: "POST",
            // url: this.BASE_URL + "/downloadFile/add",
            url: _this.UPLOAD_URL + "/mall-service/category/importData",
            data: this.formData,
            headers: {
              accessToken: localStorage.getItem("token") || sessionStorage.getItem("token"),
              securityKey: localStorage.getItem("securityKey") || sessionStorage.getItem("securityKey"),
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            this.sureBtnFlag = false;
            this.formData = new FormData();
            this.fileName = document.getElementById('fileupload').files[0].name;
            // this.hideMask();
            if (response.data.code == 0) {
              this.$message({
                type: 'success',
                message: '导入成功!'
              })
            } else {
              this.$message({
                type: 'warning',
                message: response.data.msg
              })
            }
            e.target.value = ''; //清空input中的value值 避免同一个文件二次上传无效的问题
          })
          .catch((error) => {
            this.sureBtnFlag = false;
            this.formData = new FormData();
            // this.hideMask();
            e.target.value = ''; //清空input中的value值 避免同一个文件二次上传无效的问题
          });
      },
      sureBtn() {
        this.dialogVisibleUpload = false;
        this.$refs.myList.get();
      },
      closeFun() {
        this.dialogVisibleUpload = false;
        this.$refs.myList.get();
      }
    }
  };
</script>
<style lang="scss" scope>
  .categoryDetail {
    margin: auto;
    width: 500px;

    .editBox {
      display: inline-block;
      margin: auto;

      .el-textarea {
        width: 53%;
      }

      // .el-input {
      //   width: 53%;
      // }
    }

    .stateTitle {
      display: inline-block;
      margin-left: 54px;
      margin-right: 10px;
    }
  }

  .el-switch {
    line-height: 17px;
  }

  .el-dialog {
    margin-top: 28vh !important;
    width: 703px;
  }

  .el-dialog__footer {
    text-align: center;
  }

  .dialog_bottom {
    margin-top: 23px;
    width: 100%;
    text-align: center;

    .el-button {
      width: 80px !important;
      height: 36px !important;
      padding-top: 10px;
    }
  }

  .up_add_bg {
    width: 95px;
    text-align: center;
    height: 30px;
    line-height: 30px;
    background: #FFFFFF;
    border: 1px solid #CFCFD7;
    border-radius: 6px;
    cursor: pointer;

    .upload-btn {
      opacity: 0;
    }
  }

  .upload_fileName {
    padding-top: 10px;
    padding-right: 5px;
  }
</style>